// @ts-nocheck

import useLogger from '@package/logger/src/use-logger';
import { AlertMessageTypes, alertService, RouterPage, translate } from '@SMART/index';

const logger = useLogger('routes');

const handleDynamicError = (e: Error) => {
  console.error(e);
  alertService.addAlert({
    hideIcon: false,
    type: AlertMessageTypes.Success,
    timeoutMs: 15000,
    message: translate('error.moduleLoadError'),
  });
  logger.debug(e.message);
  logger.debug(e.stack || '');
};

const routes = [
  {
    path: '/',
    redirect: { name: RouterPage.MainPage },
  },
  {
    path: '/main',
    name: RouterPage.MainPage,
    component: () => import('@/pages/main/MainPage.vue').catch(handleDynamicError),
  },
  {
    path: '/parental',
    name: RouterPage.ParentalPage,
    component: () => import('@/pages/parental/ParentalPage.vue').catch(handleDynamicError),
  },
  {
    path: '/parental/code/:variant?',
    name: RouterPage.ParentalCodePage,
    component: () => import('@/pages/parental/ParentalCodePage.vue').catch(handleDynamicError),
  },
  {
    path: '/parental/recover',
    name: RouterPage.ParentalCodeRecoverPage,
    component: () => import('@/pages/parental/ParentalCodeRecoverPage.vue').catch(handleDynamicError),
  },
  {
    path: '/search',
    name: RouterPage.SearchPage,
    component: () => import('@/pages/search/SearchPage.vue').catch(handleDynamicError),
  },
  {
    path: '/catalog/:genre?/',
    name: RouterPage.CatalogPage,
    component: () => import('@/pages/catalog/CatalogPage.vue').catch(handleDynamicError),
  },
  {
    path: '/collection/:page?/:size?/:id?/:type?/',
    name: RouterPage.CollectionPage,
    props: true,
    component: () => import('@/pages/collection/CollectionPage.vue').catch(handleDynamicError),
  },
  {
    path: '/channels/',
    name: RouterPage.ChannelsPage,
    component: () => import('@/pages/channels/ChannelsPage.vue').catch(handleDynamicError),
  },
  {
    path: '/channels/player/:id/',
    name: RouterPage.ChannelsPlayerPage,
    component: () => import('@/pages/channels/player/ChannelsPlayerPage.vue').catch(handleDynamicError),
  },
  {
    path: '/my-channel/:page?/:size?/:id?/:type?/',
    name: RouterPage.MyChannelPage,
    props: true,
    component: () => import('@/pages/my-channel/MyChannelPage.vue').catch(handleDynamicError),
  },
  {
    path: '/moments/:page?/:size?/:id?/:type?/',
    name: RouterPage.MomentsPage,
    props: true,
    component: () => import('@/pages/my-channel/MomentsPage.vue').catch(handleDynamicError),
  },
  {
    path: '/offers/',
    name: RouterPage.Offers,
    component: () => import('@/pages/offers/OffersPage.vue').catch(handleDynamicError),
  },
  {
    path: '/offer-info/:offerId/',
    name: RouterPage.OfferInfo,
    props: true,
    component: () => import('@/pages/offers/OfferInfoPage.vue').catch(handleDynamicError),
  },
  {
    path: '/debug/device/',
    name: RouterPage.DeviceInfo,
    props: true,
    component: () => import('@/pages/debug/DeviceInfoPage.vue').catch(handleDynamicError),
  },
  {
    path: '/debug/log/',
    name: RouterPage.LogInfo,
    props: true,
    component: () => import('@/pages/debug/LogInfoPage.vue').catch(handleDynamicError),
  },
  {
    path: '/settings/',
    name: RouterPage.SettingsPage,
    component: () => import('@/pages/settings/SettingsPage.vue').catch(handleDynamicError),
    redirect: {
      name: RouterPage.PersonalData,
    },
    children: [
      {
        path: 'subscriptions/',
        name: RouterPage.Subscriptions,
        redirect: {
          name: RouterPage.UserSubscriptions,
        },
      },
      {
        path: 'subscriptions/all/',
        name: RouterPage.UserSubscriptions,
        component: () => import('@/pages/settings/components/UserSubscriptions.vue').catch(handleDynamicError),
      },
      {
        path: 'subscriptions/device-info/?offerId=:offerId/',
        name: RouterPage.SubscriptionInfo,
        props: true,
        component: () => import('@/components/subscription-info/SubscriptionInfo.vue').catch(handleDynamicError),
      },
      {
        path: 'personal/',
        name: RouterPage.PersonalData,
        component: () => import('@/pages/settings/components/PersonalData.vue').catch(handleDynamicError),
      },
      {
        path: 'help/',
        name: RouterPage.HelpContacts,
        component: () => import('@/pages/settings/components/HelpContacts.vue').catch(handleDynamicError),
      },
    ],
  },
  {
    path: '/profiles/',
    name: RouterPage.ProfilesPage,
    component: () => import('@/pages/profile/ProfilesPage.vue').catch(handleDynamicError),
  },
  {
    path: '/profile/:id?/',
    name: RouterPage.ProfilePage,
    props: true,
    component: () => import('@/pages/profile/ProfilePage.vue').catch(handleDynamicError),
  },
  {
    path: '/auth/:type?/:variant?',
    name: RouterPage.AuthPage,
    component: () => import('@/pages/auth/AuthPage.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/player/:id/:type/',
    name: RouterPage.MediaCardPlayerPage,
    component: () => import('@/pages/media-card/MediaCardPlayer.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/series/:ageLimit/',
    name: RouterPage.MediaCardSeriesPage,
    component: () => import('@/pages/media-card/MediaCardSeriesPage.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/similar/',
    name: RouterPage.MediaCardSimilarPage,
    component: () => import('@/pages/media-card/MediaCardSimilarPage.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/collections/',
    name: RouterPage.MediaCardCollectionsPage,
    component: () => import('@/pages/media-card/MediaCardCollectionsPage.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/collections/:collectionId/',
    name: RouterPage.MediaCardCollectionsInfoPage,
    component: () => import('@/pages/media-card/MediaCardCollectionsInfoPage.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/',
    name: RouterPage.MediaCardPage,
    component: () => import('@/pages/media-card/MediaCardPage.vue').catch(handleDynamicError),
    redirect: {
      name: RouterPage.MediaCardControls,
    },
    children: [
      {
        path: '',
        name: RouterPage.MediaCardControls,
        props: true,
        component: () => import('@/pages/media-card/nested-routes/MediaCardControls.vue').catch(handleDynamicError),
      },
      {
        path: 'description',
        name: RouterPage.MediaCardDescription,
        props: true,
        component: () => import('@/pages/media-card/nested-routes/MediaCardDescription.vue').catch(handleDynamicError),
      },
      {
        path: 'persons',
        name: RouterPage.MediaCardPersons,
        props: true,
        component: () => import('@/pages/media-card/nested-routes/MediaCardPersons.vue').catch(handleDynamicError),
      },
    ],
  },
];

export default routes;
